<template>
  <v-container>
    <v-row>
      <v-alert v-if="successMessage" type="success">{{
        successMessage
      }}</v-alert>

      <v-col cols="12">
        <v-toolbar min-width="100%" dark color="primary">
          <v-card-title> Meeting Restrictions for Teachers </v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="openCreateDialog" color="primary">Add New</v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="teacherControl"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn @click="openUpdateDialog(item)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Teacher Information</v-card-title>
        <v-card-text>
          <v-select
            v-model="teacher"
            :items="teacherData"
            item-value="id"
            item-text="username"
            label="Name"
            :rules="nameRules"
          ></v-select>
          <v-text-field
            v-model="selectedTeacher.time"
            :rules="[$requiredRules, integerRule]"
            label="Time (minutes)*"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="createTeacher" color="primary">Submit</v-btn>
          <v-btn @click="createDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Update Teacher Information</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedTeacher.teacher"
            :items="teacherData"
            item-value="id"
            item-text="username"
            label="Name"
            :rules="nameRules"
          ></v-select>
          <v-text-field
            v-model="selectedTeacher.time"
            :rules="[$requiredRules, integerRule]"
            label="Time (minutes)*"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="updateTeacher" color="primary">Update</v-btn>
          <v-btn @click="updateDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  <script>
export default {
  data() {
    return {
      headers: [
        { text: "Teacher Name", value: "name" },
        { text: "Time", value: "time" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      integerRule: (value) => {
        if (!Number.isInteger(Number(value))) {
          return "Time must be an integer.";
        }
        return true;
      },
      updateDialog: false,
      selectedTeacher: {},
      teacherData: [],
      teacherControl: [],

      teacherUsernames: [],
      teacher: "",
      createDialog: false,
      successMessage: "",
      time: "",
      nameRules: [(v) => !!v || "Username is required"],
    };
  },
  mounted() {
    this.fetchTeachersControl();
    this.fetchTeachers();
  },
  computed: {
    validateForm() {
      return !!this.teacher && !!this.teacher.username && this.time;
    },
  },
  methods: {
    openUpdateDialog(teacher) {
      this.selectedTeacher = { ...teacher };
      this.updateDialog = true;
    },

    updateTeacher() {
      if (!this.integerRule(this.selectedTeacher.time)) {
        console.error("Time must be an integer.");
        return;
      }
      const data = {
        teacher: this.selectedTeacher.teacher,
        time: this.selectedTeacher.time,
      };

      // Use the teacher's ID for updating
      const teacherId = this.selectedTeacher.id;

      this.$api
        .patch(`/video_control/${teacherId}/`, data)
        .then((response) => {
          console.log(response.data);
          this.successMessage = "Updated successfully";
          setTimeout(() => {
            this.successMessage = "";
          }, 5000);
          this.fetchTeachersControl();
        })
        .catch((error) => {
          console.error("Error updating teacher:", error);
          this.successMessage = "";
        })
        .finally(() => {
          this.updateDialog = false;
        });
    },

    createTeacher() {
      if (!this.integerRule(this.selectedTeacher.time)) {
        console.error("Time must be an integer.");
        return;
      }
      const data = {
        teacher: this.teacher,
        time: this.time,
      };

      this.$api
        .post("/video_control/", data)
        .then((response) => {
          console.log(response.data);
          this.successMessage = "Created successfully";
          const userStatus = response.data.user_status;

          this.fetchTeachersControl();
          setTimeout(() => {
            this.successMessage = "";
          }, 5000);
        })
        .catch((error) => {
          console.error("Error creating teacher:", error);
          this.successMessage = "";
        })
        .finally(() => {
          this.createDialog = false; 
        });
    },
    fetchTeachersControl() {
      this.$api
        .get("video_control")
        .then((response) => {
          this.teacherControl = response.data.results;
        })
        .catch((error) => {
          console.error("Error fetching teacher data:", error);
        });
    },
    fetchTeachers() {
      this.$api
        .get("teachers_list")
        .then((response) => {
          this.teacherData = response.data.results;
        })
        .catch((error) => {
          console.error("Error fetching teacher data:", error);
        });
    },
    openCreateDialog() {
      this.createDialog = true;
    },
  },
};
</script>
  